import { Link } from 'gatsby'

import subscriptionImg from '~/assets/images/subscription/girl-newsletter.svg'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'

import * as containerStyles from './SubscriptionComplete.module.scss'

const SubscriptionComplete = () => (
  <MainLayout isTransparent isStaticChat>
    <PrimeSectionLeadMagnets
      title="You have successfully subscribed!"
      description={
        <>
          This is your official confirmation. Thanks for joining Codica
          newsletter. While you wait for the next issue of Codica, check out the
          latest
          <Link to="/blog/" className="mx5" key="keyLinkBlog">
            articles
          </Link>
          and
          <Link to="/case-studies/" className="ml5" key="keyLinkCase">
            case studies
          </Link>
          .
        </>
      }
      maxWidthDescription="770px"
    />
    <div className={containerStyles.subscriptionComplete__image}>
      <img
        src={subscriptionImg}
        alt="You have successfully subscribed | Codica"
        title="You have successfully subscribed"
        loading="lazy"
      />
    </div>
  </MainLayout>
)

export default SubscriptionComplete
